<template>
  <div>
    <div class="pageMain">
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        label-position="left"
        class="demo-form-inline"
      >
        <div class="tabClass">
            <el-tabs v-model="activeName" @tab-click="handleClick" stretch>
                <el-tab-pane label="按商品" name="1"></el-tab-pane>
                <el-tab-pane label="按采购员" name="2"></el-tab-pane>
            </el-tabs>
        </div>
        <el-form-item label="单据日期：">
          <el-date-picker @change="changedatepicker($event)" :default-time="['00:00:00','23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" v-model="datePicker" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="商品：">
          <goods-change ref="goodsChange" @inputChange="goodsInputEvent($event, 'searchForm')" @popupClick="getPopupGoods($event, 'searchForm')" @goodsSelectEvent="goodsDataEvent($event, 'searchForm')"></goods-change>
        </el-form-item>
        <el-form-item label="商品类别：" v-if="activeName=='1'">
          <el-cascader :options="goodsType" :show-all-levels="false" v-model="goodsTypeId" placeholder="请选择商品类型" :props="{
            checkStrictly: true,
            label: 'name',
            value: 'id',
            children: 'childs',
          }" clearable @change="cascaderChange"></el-cascader>
        </el-form-item>
        <el-form-item label="仓库：">
          <el-select v-model="searchForm.warehouseId" placeholder="请选择仓库">
            <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采购员：" v-if="activeName=='2'">
          <el-input
            v-model="searchForm.buyer"
            placeholder="请输入采购员"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchSubmit('searchForm')"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">采购单汇总</p>

        <template>
            <div class="tableTopBtn">
            <el-button size="mini" type="primary" @click="exportData(activeName)" v-if="exportButton == '1'" :disabled="tableData.length == 0">导出</el-button>

          </div>
          <el-table
            ref="lazyTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            height="45vh"
          >
            <el-table-column prop="buyer" label="采购员" v-if="activeName=='2'"></el-table-column>
            <!-- <el-table-column prop="code" label="商品编码"></el-table-column> -->
            <el-table-column prop="category" label="商品类型"> </el-table-column>
            <el-table-column prop="goodsName" label="名称">
              <template slot-scope="scope">
                <p class="numberId" @click="$router.push({ name: 'purchaseOrderDetail', params: {id: scope.row.goodsId }})">{{scope.row.goodsName}}</p>
              </template>
            </el-table-column>
            <el-table-column prop="standard" label="规格型号"> </el-table-column>
            <el-table-column prop="warehouseName" label="仓库"></el-table-column>
            <el-table-column prop="unit" label="多单位"></el-table-column>
            <el-table-column prop="num" label="多单位数量"></el-table-column>
            <el-table-column prop="unit" label="单位"></el-table-column>
            <el-table-column prop="num" label="单位数量"></el-table-column>
            <el-table-column prop="price" label="单价">
              <template slot-scope="scope">{{ scope.row.price }} 元</template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="采购金额">
              <template slot-scope="scope">{{ scope.row.totalPrice }} 元</template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            当前第 {{ pagesData.currentPage }} 页，共 {{ pagesData.pages }} 页 {{ pagesData.total }} 条；
            <el-select v-model="pagesData.currentRows" @change="currentRowsChange">
              <el-option v-for="(item,index) in pagesData.rows" :key="index" :value="item" :label="item+'条/页'"></el-option>
            </el-select>
            <!-- <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage"
              :page-sizes="pagesData.rows"
              :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagesData.total"
            >
            </el-pagination> -->
          </div>
          <div class="totalNum">
            <div>合计：</div>
            <div><span>数量：{{numSum}}</span><span style="margin-left:50px">金额：{{priceSum}} 元</span></div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
//vue实例
import goodsChange from "../components/goodsChange.vue";
export default {
  data() {
    return {
      loading: true,
      //搜索
      datePicker: [],
      searchForm: {
        goodsName: "",
        buyer:"",
        warehouseId:"",
        categoryId:"",
        startTime:"",
        endTime:"",
      },
      tableData: [],
      tabWidth: 200,
      exportButton:'1',
      numSum:'',
      priceSum:'',
      activeName:'1',
      warehouseList: [], //仓库列表
      goodsTypeId: [],
      goodsType: [],
      //分页数据
      pagesData: {
        pages:0,
        total: 0,
        currentPage: 1,
        currentRows: 1000,
        rows: [100, 1000, 5000, 10000, 100000],
      },
      // 懒加载标识
      lazyFlag:true,
    };
  },
  components: {
    goodsChange
  },
  created() {
    
    // var privilege = JSON.parse(sessionStorage.getItem("authority"));
    // privilege.forEach((item) => {
    //   if (item.authority == "sys_menu_query") {
    //     this.searchButton = "1";
    //   } else if (item.authority == "sys_menu_add") {
    //     this.exportButton = "1";
    //   }
    // });
  },
  // watch: {
  //   // 如果路由有变化，会再次执行该方法
  //   $route: "listEvent",
  // },
  mounted(){
    this.tableData = []
    this.listEvent();
    this.getWarehouse();
    this.getGoodsType();
    // 表格懒加载
    let table = this.$refs.lazyTable.$el;
    table.addEventListener('scroll', (res) => {
      // console.log(res.target.scrollHeight-50, Number((res.target.scrollTop).toFixed(2)), res.target.clientHeight)
      if (Number((res.target.scrollTop).toFixed(2)) + res.target.clientHeight > res.target.scrollHeight-10) {
        if (this.lazyFlag == true && this.tableData.length < this.pagesData.total && this.pagesData.currentPage < this.pagesData.pages) {
          this.$set(this.pagesData, 'currentPage', this.pagesData.currentPage + 1)
          this.listEvent();
          this.lazyFlag = false
        }

      }
    }, true)
  },
  methods: {
    // 分页设置
    currentRowsChange(e){
      this.$set(this.pagesData,'currentRows',e)
      this.$set(this.pagesData,'currentPage',1)
      this.listEvent()
    },
    goodsInputEvent(data,formName){
      this.$set(this[formName], 'goodsName', '')
      this.$set(this[formName], 'goodsId', '')
    },
    getPopupGoods(data, formName) {
      console.log(data,formName)
      this.$set(this[formName], 'goodsName', data.goodsName)
      this.$set(this[formName], 'goodsId', data.id)
    },
    goodsDataEvent(data, formName) {
      let goodsIds = [];
      let names = []
      data.forEach(item => {
        goodsIds.push(item.id)
        names.push(item.goodsName)
      })
      this.$refs.goodsChange.goodsName = names.join(',')
      this.$set(this[formName], 'goodsId', goodsIds.join(','))
    },
    changedatepicker(e) {
      this.searchForm.startTime = e[0]
      this.searchForm.endTime = e[1]
    },
    listEvent(act) {
      var _this = this;
      var url = '';
      if(act=='2'){
        url = "/purchaseOrderStatistics/getListByBuyer"
      }else{
        url = "/purchaseOrderStatistics/getListByGoods";
      }
      this.loading = true;
      _this.searchForm.current = _this.pagesData.currentPage
      _this.searchForm.size = _this.pagesData.currentRows
      console.log(_this.searchForm)
      //获取列表
      this.$axios
        .get(_this.$axios.defaults.basePath + url, {
          params: _this.searchForm,
        })
        .then(function (res) {
          var resData = res.data;
          if (res.data.errcode == 0) {
            _this.loading = false;
            if (_this.tableData.length == 0) {
              _this.tableData = resData.data.list.records;
            } else {
              _this.tableData = _this.tableData.concat(resData.data.list.records);
            }
            _this.pagesData.total = resData.data.list.total;
            _this.pagesData.pages = resData.data.list.pages;
            _this.numSum = resData.data.numSum;
            _this.priceSum = resData.data.priceSum;
            if(_this.lazyFlag == false && _this.pagesData.currentPage == _this.pagesData.pages){
              _this.$message({
                message: '已经是最后一页了',
                type: 'info'
              })
            }
            _this.lazyFlag = true
          } else {
            
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //侧边栏伸缩
    openAside() {
      if (this.isCollapse) {
        this.isCollapse = false;
        this.tabWidth = 200;
      } else {
        this.isCollapse = true;
        this.tabWidth = 64;
      }
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent(this.activeName);
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent(this.activeName);
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.tableData = []
      this.$set(this.searchForm, 'goodsName', this.$refs.goodsChange._data.goodsName)
      console.log(this.$refs.goodsChange._data.goodsName)
      if(!this.$refs.goodsChange._data.goodsName){
        this.$set(this.searchForm, 'goodsId', '')
      }
      this.listEvent(this.activeName);
    },
    //表单重置
    resetForm(formName) {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.$set(this.searchForm,'goodsName','');
      this.$refs.goodsChange.goodsName = ''
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.tableData = []
      this.listEvent();
      this.datePicker = [];
    },
    //GET 仓库
    getWarehouse() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.warehouseList = res.data.data;
          }
        });
    },
    exportData(act) {
      var _this = this;
      var url = '';
      if(act=='2'){
        url = "/purchaseOrderStatistics/exportListByBuyeer"
      }else{
        url = "/purchaseOrderStatistics/exportListByGoods";
      }

      this.$axios.get(this.$axios.defaults.basePath + url, {
          params: {
            goodsName: _this.searchForm.goodsName,
            buyer: _this.searchForm.buyer,
            warehouseId:_this.searchForm.warehouseId,
            categoryId:_this.searchForm.categoryId,
            startTime: _this.searchForm.startTime,
            endTime: _this.searchForm.endTime,
          },
          responseType: 'blob'
        })
        .then((res) => {
            var eleLink = document.createElement("a");
            eleLink.download = "采购单汇总.csv";
            eleLink.style.display = "none";

            var blob = new Blob([res.data]);
            eleLink.href = URL.createObjectURL(blob);
            console.info(blob);
            // 触发点击
            document.body.appendChild(eleLink);
            eleLink.click();
            _this.exportShow = false;
            _this.$message({
              message: "导出成功",
              type: "success",
            });
            _this.exportForm = {};
            // 然后移除
            document.body.removeChild(eleLink);
        })
      
    },
    handleClick() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
        this.listEvent(this.activeName);
      },
    
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },

    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
            "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.totalNum{
    display: flex;
    justify-content: space-between;
    margin: 20px 50px 20px 20px;
    font-weight: 550;
}
::v-deep .tabClass{
    display: flex;
    position: inherit;
    justify-content: center;
}
::v-deep .el-tabs__item{
    width: 150px;
}
::v-deep .el-tabs_nav-scroll{
    width:30%!important;
    margin:0 auto!important;
}
</style>